import React from 'react'

function Image({src,close}) {
  return (
    <div className='gir image-select position-absolute'>
                

        <div className="tr bg-mavi p-2 fw-bold d-flex justify-content-between align-items-center ">Resim Görüntüleyicisi
        <div onClick={e=>{close()}} className="bad cursor-pointer ">
            <i className="fa fa-close text-white fs-4"></i>
        </div>
        </div>
        <div className="kutu " style={{borderTopLeftRadius:0,borderTopRightRadius:0}}>

            <div className="resim">
                <img className='img-fluid' src={src} alt="" />
                
            </div>
        </div>
    </div>
  )
}

export default Image