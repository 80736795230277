import axios from "axios";
import React, { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";

function Sidebar({
  yer,
  mode,
  back,
  yerler,
  openDetail,
  imageOpen,
  setZiyaret,
  imageSrc,
  setT,
}) {
  const [data, setData] = useState(null);
  const [tab, setTab] = useState(0);

  function setTheme(e, i) {
    //   setThemeIndex(i);
    document.querySelectorAll(".selector").forEach((el) => {
      el.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
  }
  useEffect(() => {
    setData(null);
    axios.get(`api/getYer/${yer}`).then((res) => {
      const arr = res.data.foto;
      setData(res.data[0]);
    });
  }, [yer]);
  return (
    <div
      className={
        mode == 0
          ? "sidebar position-absolute  sd-mod-1"
          : "sidebar position-absolute sd-mod-2"
      }
    >
      {mode == 0 ? (
        <>
          <div className="gir kutu d-none justify-content-center align-items-center ">
            <div className="text-mavi fs-3 fw-bold">GPS MAP CAMERA</div>
          </div>

          <div className="kutu-sec p-0 mt-3 gir os">
            <div className="p-2">
              <div className="text-mavi fw-bold fs-5">Yerler</div>
              <div className="search mb-3 mt-1">
                <i className="fa fa-search"></i>
                <input type="text" placeholder="Arama..." />
              </div>

              {yerler &&
                yerler.map((val) => {
                  return (
                    <div
                      onClick={(e) => {
                        e.currentTarget
                          .querySelector("i")
                          .classList.remove("fa-caret-right");
                        e.currentTarget
                          .querySelector("i")
                          .classList.add("fa-caret-down");
                        if (e.currentTarget.classList.contains("acik")) {
                          e.currentTarget.classList.remove("acik");
                        } else {
                          e.currentTarget.classList.add("acik");
                        }

                        e.currentTarget
                          .querySelectorAll(".down-1")
                          .forEach((el) => {
                            el.classList.remove("down-off");
                          });
                      }}
                      className="ilk"
                      key={val.id}
                      title={val.name}
                    >
                      <i class="fa-solid fa-caret-right text-mavi fs-5 me-2"></i>
                      {val.name}
                      {val.ilceler.map((v) => {
                        return (
                          <div
                            onClick={(e) => {
                              e.currentTarget
                                .querySelectorAll(".down-2")
                                .forEach((el) => {
                                  if (el.classList.contains("down-off")) {
                                    el.classList.remove("down-off");
                                    e.currentTarget
                                      .querySelector("i")
                                      .classList.remove("fa-caret-right");
                                    e.currentTarget
                                      .querySelector("i")
                                      .classList.add("fa-caret-down");
                                  } else {
                                    el.classList.add("down-off");
                                    e.currentTarget
                                      .querySelector("i")
                                      .classList.add("fa-caret-right");
                                    e.currentTarget
                                      .querySelector("i")
                                      .classList.remove("fa-caret-down");
                                  }
                                });
                            }}
                            className={
                              v.musteriler.length == 0
                                ? "fs down down-1 down-off no-mus"
                                : "fs down down-1 down-off"
                            }
                            key={v.ilce_id}
                          >
                            {v.musteriler.length != 0 ? (
                              <i class="fa-solid fa-caret-right text-mavi fs-5 me-2"></i>
                            ) : (
                              ""
                            )}
                            {v.ilce_adi}

                            <div className="tr-2">
                              {v.musteriler.map((m) => {
                                return (
                                  <div
                                    onClick={(e) => openDetail(m.musteri_id)}
                                    className="sc down down-2 down-off"
                                  >
                                    <i class="fa-solid fa-user fs-6 text-mavi me-2"></i>
                                    {m.name}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="gir kutu p-0 mt-3">
            <div className="tab-con">
              <div
                onClick={(e) => setTab(0)}
                className={tab == 0 ? "tab active" : "tab"}
              >
                <i className="fa fa-house"></i>Giriş
              </div>
              <div
                onClick={(e) => setTab(1)}
                className={tab == 1 ? "tab active" : "tab"}
              >
                <i className="fa-solid fa-circle-half-stroke"></i>Stil
              </div>
            </div>
            {tab == 0 ? (
              <ul className="links">
                <li>
                  <Link to={"/camera"}>
                    {" "}
                    <i className="fa fa-camera"></i> Kamera
                  </Link>
                </li>
                <li>
                  <Link to={"/"}>
                    {" "}
                    <i className="fa fa-gear"></i> Ayarlar
                  </Link>
                </li>
              </ul>
            ) : (
              ""
            )}

            {tab == 1 ? (
              <div className="theme-select">
                <div onClick={(e) => setT(e, 0)} className="active selector">
                  <img src="/theme-select/standart.png" />
                </div>
                <div onClick={(e) => setT(e, 1)} className="selector">
                  <img src="/theme-select/silver.png" />
                </div>
                <div onClick={(e) => setT(e, 2)} className="selector">
                  <img src="/theme-select/retro.png" />
                </div>
                <div onClick={(e) => setT(e, 3)} className="selector">
                  <img src="/theme-select/dark.png" />
                </div>

                <div onClick={(e) => setT(e, 4)} className="selector">
                  <img src="/theme-select/night.png" />
                </div>

                <div onClick={(e) => setT(e, 5)} className="selector">
                  <img src="/theme-select/aber.png" />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
      {mode == 1 ? (
        <div className="gir kutu">
          <div className="d-flex align-items-center justify-content-between">
            <div className="flex-fill">
              <div className="d-none text-white opacity-50 fw-bold fs-5 lh-1 mb-0">
                İZMİR
              </div>
              <div className="text-mavi lh-1 fw-bold fs-2">
                {data ? data.name : <div className="sk sk-big"></div>}
              </div>
            </div>

            <i onClick={(e) => back()} className="fa fa-arrow-left back"></i>
          </div>

          <div className="row mt-3 gy-3">
            <div className="col-6">
              <div className="text-white fw-bold lh-1">Yetkili</div>
              <div className="text-white opacity-75">
                {data ? data.ad : <div className="sk"></div>}
              </div>
              <div className="text-white opacity-75 lh-1">
                {data ? data.mail : <div className="sk"></div>}
              </div>
            </div>

            <div className="col-6">
              <div className="text-white fw-bold lh-1">Sektör</div>
              <div className="text-white opacity-75 lh-1">
                {data ? data.sektor : <div className="sk"></div>}
              </div>
            </div>

            <div className="col-6">
              <div className="text-white fw-bold lh-1">Koordinatlar</div>
              <div className="text-white opacity-75">
                {data ? data.lat : <div className="sk"></div>}
              </div>
              <div className="text-white opacity-75 lh-1">
                {data ? data.lng : <div className="sk"></div>}
              </div>
            </div>

            <div className="col-6">
              <div className="text-white fw-bold lh-1">Adres</div>
              <div className="text-white opacity-75 lh-1">
                {data ? data.adres : <div className="sk"></div>}
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="text-white fw-bold lh-1">Not</div>
            <div className="text-white opacity-75 lh-1">
              {data ? data.note : <div className="sk-big sk"></div>}
            </div>
          </div>

          <div className="text-mavi fs-5 fw-bold mt-4">Fotoğraflar</div>
          <div className="row">
            {data ? (
              data.foto.split(",").map((foto, i) => {
                if (i > (data.foto.split(",").length - 1) / 2) {
                  return (
                    <div key={i} className="col-6 imgasex">
                      <img
                        onClick={(e) => {
                          imageOpen(
                            `http://192.168.1.80/map-backend/assets/img/musteri/${foto}`
                          );
                        }}
                        src={`http://192.168.1.80/map-backend/assets/img/musteri/${foto}`}
                        className="img-fluid br hover-fade cursor-pointer"
                        style={{ maxHeight: 100, height: 100 }}
                        alt=""
                      />
                    </div>
                  );
                }
              })
            ) : (
              <>
                <div className="col-6">
                  <div className="sk sk-full"></div>
                </div>{" "}
                <div className="col-6">
                  <div className="sk sk-full"></div>
                </div>
              </>
            )}
          </div>

          {data ? (
            <div className="row mt-3 gx-2 gy-2">
              <div className="col-12">
                <button className="button button-mavi fw-bold w-100 d-block">
                  <i className="fa-solid fa-location-arrow"></i> Yol Tarifi Al
                </button>
              </div>

              <div className="col-12">
                <button
                  onClick={(e) => setZiyaret()}
                  className="button button-mavi fw-bold w-100 d-block"
                >
                  <i className="fa-solid fa-clock-rotate-left"></i> Geçmiş
                  Ziyaretler
                </button>
              </div>

              <div className="col-6">
                <button className="button button-yesil fw-bold w-100 d-block">
                  <i className="fa-solid fa-edit"></i> Düzenle
                </button>
              </div>
              <div className="col-6">
                <button className="button button-red fw-bold w-100 d-block">
                  <i className="fa-solid fa-trash"></i> Sil
                </button>
              </div>
            </div>
          ) : (
            <div className="row mt-3 gx-2 gy-2">
              <div className="col-12">
                <div className="sk sk-big"></div>{" "}
              </div>

              <div className="col-12">
                <div className="sk sk-big"></div>
              </div>

              <div className="col-6">
                <div className="sk sk-big"></div>
              </div>
              <div className="col-6">
                <div className="sk sk-big"></div>
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}

      {mode == 2 ? (
        <div className="kutu-sec p-2 fw-500 mt-3 gir ">
          <div className="text-white fs-5">
            {" "}
            <i className="text-mavi fa-solid fa-clock-rotate-left"></i> Geçmiş
            Ziyaretler
          </div>

          <div className="mt-3"></div>
          <div className="ziyaret">
            <div className="tarih text-mavi">Tarih</div>
            <div className="ziyaretci text-mavi">Ziyaretçi</div>
          </div>

          <div
            className="ziyaret"
            onClick={(e) => {
              if (
                e.currentTarget.nextElementSibling.nextElementSibling.classList.contains(
                  "kapali"
                )
              ) {
                e.currentTarget.nextElementSibling.nextElementSibling.classList.remove(
                  "kapali"
                );
                e.currentTarget
                  .querySelector("i")
                  .classList.remove("fa-caret-right");
                e.currentTarget
                  .querySelector("i")
                  .classList.add("fa-caret-down");
                e.currentTarget.classList.add("ziyaret-active");
                e.currentTarget.nextElementSibling.style.display = "none";
              } else {
                e.currentTarget.nextElementSibling.nextElementSibling.classList.add(
                  "kapali"
                );
                e.currentTarget
                  .querySelector("i")
                  .classList.add("fa-caret-right");
                e.currentTarget
                  .querySelector("i")
                  .classList.remove("fa-caret-down");
                e.currentTarget.classList.remove("ziyaret-active");
                e.currentTarget.nextElementSibling.style.display = "";
              }
            }}
          >
            <div className="tarih">
              <i className="fa fa-caret-right text-mavi"></i> 08.04.2022
            </div>
            <div className="ziyaretci">Emir</div>
          </div>
          <div className="border"></div>
          <div className="detail kapali text-white">
            <div className="text-white ">
              Görüşülen Kişi:{" "}
              <span className="opacity-75 fw-normal">Emr Tan</span>
            </div>
            <div className="text-white ">
              Satın Alma Sıklığı:{" "}
              <span className="opacity-75 fw-normal">1 Ay</span>
            </div>
            <div className="text-white mb-2">
              Satın Alma Hacmi:{" "}
              <span className="opacity-75 fw-normal">5 Kilo</span>
            </div>
            <div className="text-white ">Ziyaret Notu</div>
            <div className="text-white lh-1 opacity-75 fw-normal">
              asdsad asjkdsad wdas
            </div>
          </div>
        
          <div
            className="ziyaret"
            onClick={(e) => {
              if (
                e.currentTarget.nextElementSibling.nextElementSibling.classList.contains(
                  "kapali"
                )
              ) {
                e.currentTarget.nextElementSibling.nextElementSibling.classList.remove(
                  "kapali"
                );
             
                e.currentTarget
                  .querySelector("i")
                  .classList.remove("fa-caret-right");
                e.currentTarget
                  .querySelector("i")
                  .classList.add("fa-caret-down");
                e.currentTarget.classList.add("ziyaret-active");
                e.currentTarget.nextElementSibling.style.display = "none";

                
              } else {
                e.currentTarget.nextElementSibling.nextElementSibling.classList.add("kapali");
                e.currentTarget
                  .querySelector("i")
                  .classList.add("fa-caret-right");
                e.currentTarget
                  .querySelector("i")
                  .classList.remove("fa-caret-down");
                e.currentTarget.classList.remove("ziyaret-active");
                e.currentTarget.nextElementSibling.style.display = "";
              }
            }}
          >
            <div className="tarih">
              <i className="fa fa-caret-right text-mavi"></i> 08.04.2022
            </div>
            <div className="ziyaretci">Emir</div>
          </div>
          <div className="border"></div>
          <div className="detail kapali text-white">
            <div className="text-white ">
              Görüşülen Kişi:{" "}
              <span className="opacity-75 fw-normal">Emr Tan</span>
            </div>
            <div className="text-white ">
              Satın Alma Sıklığı:{" "}
              <span className="opacity-75 fw-normal">1 Ay</span>
            </div>
            <div className="text-white mb-2">
              Satın Alma Hacmi:{" "}
              <span className="opacity-75 fw-normal">5 Kilo</span>
            </div>
            <div className="text-white ">Ziyaret Notu</div>
            <div className="text-white lh-1 opacity-75 fw-normal">
              asdsad asjkdsad wdas
            </div>
          </div>

          <div
            className="ziyaret"
            onClick={(e) => {
              if (
                e.currentTarget.nextElementSibling.nextElementSibling.classList.contains("kapali")
              ) {
                e.currentTarget.nextElementSibling.nextElementSibling.classList.remove("kapali");
                e.currentTarget
                  .querySelector("i")
                  .classList.remove("fa-caret-right");
                e.currentTarget
                  .querySelector("i")
                  .classList.add("fa-caret-down");
                e.currentTarget.classList.add("ziyaret-active");
                e.currentTarget.nextElementSibling.style.display = "none";
              } else {
                e.currentTarget.nextElementSibling.nextElementSibling.classList.add(
                  "kapali"
                );
                e.currentTarget
                  .querySelector("i")
                  .classList.add("fa-caret-right");
                e.currentTarget
                  .querySelector("i")
                  .classList.remove("fa-caret-down");
                e.currentTarget.classList.remove("ziyaret-active");
                e.currentTarget.nextElementSibling.style.display = "";
              }
            }}
          >
            <div className="tarih">
              <i className="fa fa-caret-right text-mavi"></i> 08.04.2022
            </div>
            <div className="ziyaretci">Emir</div>
          </div>
          <div className="border"></div>
          <div className="detail kapali text-white">
            <div className="text-white ">
              Görüşülen Kişi:{" "}
              <span className="opacity-75 fw-normal">Emr Tan</span>
            </div>
            <div className="text-white ">
              Satın Alma Sıklığı:{" "}
              <span className="opacity-75 fw-normal">1 Ay</span>
            </div>
            <div className="text-white mb-2">
              Satın Alma Hacmi:{" "}
              <span className="opacity-75 fw-normal">5 Kilo</span>
            </div>
            <div className="text-white ">Ziyaret Notu</div>
            <div className="text-white lh-1 opacity-75 fw-normal">
              asdsad asjkdsad wdas
            </div>
          </div>

        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Sidebar;