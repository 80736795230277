import {
  GoogleMap,
  withScriptjs,
  Marker,
  withGoogleMap,
  Circle,
  Polyline,
} from "react-google-maps";
import { useState, useEffect, useRef, Fragment } from "react";
import "./assets/css/style.css";
import Sidebar from "./components/Sidebar";
import mapProps from "./mapProps";
import axios from "axios";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import Image from "./components/Image";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Camera from "./pages/Camera";

const MyMapComponent = withScriptjs(
  withGoogleMap((props) => {
    const [lat, setLat] = useState(props.p1);
    const [lng, setLng] = useState(props.p2);
    const [parkingLot, setParkingLot] = useState(20);
    return (
      <GoogleMap
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: false,
          fullscreenControl: false,
          styles: props.styles,
        }}
        id="example-map"
        defaultZoom={14}
        defaultCenter={{ lat: lat, lng: lng }}
      >
        {props.yerler}
      </GoogleMap>
    );
  })
);

function App() {
  const [themeIndex, setThemeIndex] = useState(0);
  const [sideMode, setSideMode] = useState(0);
  const [yerler, setYerler] = useState(null);
  const [yer, setYer] = useState(null);
  const [src, setSrc] = useState("");
  const [window, setWindow] = useState("");
  const [mode, setMode] = useState(0);

  useEffect(() => {
    axios.get("api/getYerler/" + localStorage.getItem("pid")).then((res) => {
      console.log(res);

      setYerler(res.data);
    });
  }, []);

  function setInfo(i) {
    setYer(i);
    setSideMode(999);
    setSideMode(1);
  }

  function setTheme(e, i) {
    setThemeIndex(i);
    document.querySelectorAll(".selector").forEach((el) => {
      el.classList.remove("active");
    });
    console.log(e.target);
    e.currentTarget.classList.add("active");
  }
  return (
    <Router>
      <Routes>
        <Route
          element={
            <>
              {" "}
              <div className="all">
                <Sidebar
                setT={(e,i)=>{
                  setTheme(e,i)
                }}
                  imageOpen={(e) => {
                    setSrc(e);
                    setWindow("image");
                  }}
                  openDetail={(i) => setInfo(i)}
                  yer={yer}
                  yerler={yerler}
                  mode={sideMode}
                  back={(e) => setSideMode(0)}
                  setZiyaret={e=>setSideMode(2)}
                />

                {window == "image" ? (
                  <>
                    {" "}
                    <div className="bg-opa"></div>
                    <Image close={(e) => setWindow("")} src={src} />{" "}
                  </>
                ) : (
                  ""
                )}
                <div className="d-none theme-select">
                  <div
                    onClick={(e) => setTheme(e, 0)}
                    className="active selector"
                  >
                    <img src="/theme-select/standart.png" />
                  </div>
                  <div onClick={(e) => setTheme(e, 1)} className="selector">
                    <img src="/theme-select/silver.png" />
                  </div>
                  <div onClick={(e) => setTheme(e, 2)} className="selector">
                    <img src="/theme-select/retro.png" />
                  </div>
                  <div onClick={(e) => setTheme(e, 3)} className="selector">
                    <img src="/theme-select/dark.png" />
                  </div>

                  <div onClick={(e) => setTheme(e, 4)} className="selector">
                    <img src="/theme-select/night.png" />
                  </div>

                  <div onClick={(e) => setTheme(e, 5)} className="selector">
                    <img src="/theme-select/aber.png" />
                  </div>
                </div>
                {yerler != null ? (
                  <MyMapComponent
                    isMarkerShown
                    isRadius
                    styles={mapProps[themeIndex]}
                    isRoad
                    musteriSelect={(e) => {
                      console.log(e);
                      setSideMode(9999);
                      setSideMode(1);
                    }}
                    p1={38.4161411934773}
                    p2={27.018369774704905}
                    yerler={
                      mode == 0 ? (
                        yerler.map((yer) => {
                          return yer.ilceler.map((ilce) => {
                            return ilce.musteriler.map((m) => {
                              return (
                                <>
                                  <Marker
                                    key={m.ilce_id}
                                    onClick={(e) => {
                                      console.log(m.ilce_id);

                                      setInfo(m.ilce_id);
                                    }}
                                    icon={"/marker.png"}
                                    position={{
                                      lat: Number(m.lat),
                                      lng: Number(m.lng),
                                    }}
                                  />
                                  <InfoBox
                                    options={{
                                      closeBoxURL: "",
                                      enableEventPropagation: true,
                                    }}
                                    position={{
                                      lat: Number(m.lat),
                                      lng: Number(m.lng),
                                    }}
                                  >
                                    <div
                                      onClick={(e) => {
                                        console.log("s");

                                        console.log(m.musteri_id);
                                        setInfo(m.musteri_id);
                                      }}
                                     className="tss"
                                    >
                                      <div
                                        style={{
                                          fontSize: 20,
                                          display: "flex",
                                          fontWeight:"bold",
                                          alignItems: "center",
                                        }}
                                      >
                                        {m.name}
                                     
                                      </div>
                                    </div>
                                  </InfoBox>
                                </>
                              );
                            });
                          });
                        })
                      ) : (
                        <></>
                      )
                    }
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAQCTcVjWj-hwAAmEAq74482WXYKiFG1v8&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                ) : (
                  ""
                )}
              </div>
            </>
          }
          path="/"
          exact
        ></Route>

        <Route path="camera" element={<Camera />} exact></Route>
      </Routes>
    </Router>
  );
}

export default App;
